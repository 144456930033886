import { defineStore } from 'pinia'
import { IMenuParams } from '@/types/myProfile'

export const useMainStore = defineStore('main', {
  state: () => ({
    token: '',
    dictionaryList: [], // 字典列表
    breadcrumb: [], // 当前位置 面包屑
    applicationTypes: [], // 所有业务类型
    activeMenu: '', // 选中一级菜单
    activePage: '', // 子菜单，当前页面
    menuInfo: [] as IMenuParams[], // 菜单信息
    filterMenu: [], // 扁平化后的菜单
    businessTypeId: null, // 点击菜单获取业务列表排序id
    certificateDetails: {}, // 证书信息
    selectApplyTypeData: '', // 申请类型数据
    applicationFormExcelData: '', // 申请表单excel导入数据
    countryList: [], // 国家列表
    portList: [], // 港口列表
    selectFormData: {}, // 第一步选择数据【委托检验、无线】路由相关
    remindRegister: '',
    USER_INFO: {}, // 用户信息
    thirdId: '' // 支付宝ID
  }),
  getters: {
    leftMenuList: (state) => {
      let menu: IMenuParams[] | [] = []
      state.menuInfo.forEach((item: IMenuParams) => {
        if (item.name === state.activeMenu) {
          menu = item.children ? item.children : []
        }
      })
      return menu
    }
  },
  actions: {

  }
})
