import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'internationalProduct',
  name: 'internationalProduct',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'internationalWorkBench',
      component: () => import(/* webpackChunkName: "internationalWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    // NB申请单
    {
      path: 'myApplication',
      name: 'internationalMyApplication',
      component: RouterView,
      children: [
        {
          path: 'fillInApplication',
          name: 'internationalFillInApplication',
          component: () => import(/* webpackChunkName: "internationalFillInApplication" */ '@/components/applicationForm/applicationInfo.vue')
        },
        {
          path: 'fillInApplication/NBSelectType',
          name: 'NBapplicationSelectType',
          component: () => import(/* webpackChunkName: "NBapplicationSelectType" */ '@/views/internationalProduct/NBCertificate/selectType/index.vue')
        },
        {
          path: 'fillInApplication/NBApplicationForm',
          name: 'NBApplicationForm',
          component: () => import(/* webpackChunkName: "NBApplicationForm" */ '@/views/internationalProduct/NBCertificate/applicationForm/index.vue')
        },
        {
          path: 'fillInApplication/NBApplicationForm/:id',
          name: 'NBApplicationFormDetail',
          component: () => import(/* webpackChunkName: "NBApplicationFormDetail" */ '@/views/internationalProduct/NBCertificate/applicationForm/index.vue')
        },
        {
          path: 'fillInApplication/NBAdditionalInfo',
          name: 'NBAdditionalInfo',
          component: () => import(/* webpackChunkName: "NBAdditionalInfo" */ '@/views/internationalProduct/NBCertificate/additionalInfo/index.vue')
        },
        {
          path: 'fillInApplication/NBAdditionalInfo/:id',
          name: 'NBAdditionalInfoDetail',
          component: () => import(/* webpackChunkName: "NBAdditionalInfoDetail" */ '@/views/internationalProduct/NBCertificate/additionalInfo/index.vue')
        },
        {
          path: 'applicationDrafts',
          name: 'internationalDrafts',
          component: () => import(/* webpackChunkName: "internationalDrafts" */ '@/views/communication/VOCApplication/applicationDrafts/index.vue')
        },
        {
          path: 'applicationList',
          name: 'internationalList',
          component: () => import(/* webpackChunkName: "internationalList" */ '@/views/communication/VOCApplication/applicationList/index.vue')
        },
        {
          path: 'DraftCertificate',
          name: 'internationalDraftCertificate',
          component: () => import('@/views/communication/VOCApplication/applicationList/DraftCertificate.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'internationalCertificate',
      component: () => import(/* webpackChunkName: "internationalCertificate" */ '@/views/communication/myCertificate/index.vue')
    },
    {
      path: 'myCertificate/:id',
      name: 'internationalCertificateDetail',
      component: () => import(/* webpackChunkName: "internationalCertificateDetail" */ '@/views/communication/myCertificate/detail.vue')
    },
    {
      path: 'certificationInformation',
      name: 'NBcertificationInformation',
      component: RouterView,
      children: [
        {
          path: 'invoiceInformation',
          name: 'NBinvoiceInformation',
          component: () => import(/* webpackChunkName: "invoiceInformation" */ '@/views/internationalProduct/certificationInformation/invoiceInformation/index.vue')
        },
        {
          path: 'postalManagement',
          name: 'NBpostalManagement',
          component: () => import(/* webpackChunkName: "invoiceInformation" */ '@/views/internationalProduct/certificationInformation/postalManagement/index.vue')
        }
      ]
    }
  ]
}

export default routes
