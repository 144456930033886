/**
 * 清关认证与贸易支持
 */
import { createAxios } from '@/utils/request'

const request = createAxios()

/*
 * 获取业务申请类型
 */
export const getApplicationTypes = (params?: any) => {
  return request({
    method: 'GET',
    url: '/application/types',
    params: params || {}
  })
}
/*
 * 获取业务申请类型详情
 */
export const getApplicationTypesDetail = (id: any) => {
  return request({
    method: 'GET',
    url: `/application/types/${id}`
  })
}
// 我的证书列表

export const getcerts = (data: any) => {
  return request({
    method: 'GET',
    url: '/certs',
    params: data
  })
}
// 子业务类型
export const applicationTypes = (id: any) => {
  return request({
    method: 'GET',
    url: `/application/child/types/${id}`
  })
}

// 证书详情
export const getcertsinfo = (id: number) => {
  return request({
    method: 'GET',
    url: `/cert/info/${id}`
  })
}

// 申请办理情况
export const rfclist = (data: any) => {
  return request({
    method: 'GET',
    url: '/kenya/rfc/list',
    params: data
  })
}

// 确认拟稿详细信息详情
export const rfclistdraft = (id: number) => {
  return request({
    method: 'GET',
    url: `kenya/cert/draft/${id}`
  })
}
// 确认拟稿详细信息保存
export const draftPost = (id: number) => {
  return request({
    method: 'POST',
    url: `kenya/cert/draft/${id}`
  })
}
/*
 * 查询申请草稿列表
 */
export const queryDraftList = (params: any) => {
  return request({
    method: 'GET',
    url: '/kenya/rfc/draft',
    params
  })
}

/*
 * 删除申请草稿
 */
export const deleteDraft = (id: number) => {
  return request({
    method: 'DELETE',
    url: `/kenya/rfc/${id}`
  })
}

/*
 * 修改申请草稿
 */
export const editDraft = (data: any) => {
  return request({
    method: 'PUT',
    url: '',
    data
  })
}
// 获取控制台信息
export const getHome = () => {
  return request({
    method: 'GET',
    url: '/default'
  })
}
/*
 * 港口
 * type: 类型 ALL所有 ，LOAD装运港，UNLOAD卸货港
 */
export const getPort = () => {
  return request({
    method: 'GET',
    url: '/port/keyword'
  })
}
/*
 * 港口
 * type: 类型 ALL所有 ，LOAD装运港，UNLOAD卸货港
 */
export const getPortIQ = (params: any) => {
  return request({
    method: 'GET',
    url: '/port/keyword',
    params
  })
}

/*
 * 单位
 */
export const getUnit = () => {
  return request({
    method: 'GET',
    url: '/unit/keyword'
  })
}

/*
 * 新增申请单
 */
export const addApplication = (data: any) => {
  return request({
    method: 'POST',
    url: '/kenya/create',
    data
  })
}

/*
 * 导入产品数据
 */
export const importProductData = (url: string) => {
  return request({
    method: 'GET',
    url: '/kenya/product/excel',
    params: {
      url
    }
  })
}

/*
 * 申请表详情
 */
export const getApplicationDetail = (id: any, rfcId?: any) => {
  const params = !rfcId ? {} : { parent_id: rfcId }
  return request({
    method: 'GET',
    url: `/rfc/detail/${id}`,
    params
  })
}

/*
 * 申请表历史记录
 */
// export const getApplicationHistory = (params: any) => {
//   return request({
//     method: 'GET',
//     url: '/kenya/history/list',
//     params
//   })
// }

/*
 * 出口商、进口商、检验地点历史记录
 */
export const getCompanyHistory = (params: any) => {
  return request({
    method: 'GET',
    url: '/kenya/company/list',
    params
  })
}

/*
 * 申请表excel导入获取数据
 */
export const getApplicationExcel = (params: any) => {
  return request({
    method: 'GET',
    url: '/kenya/apply/excel',
    params
  })
}
