import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'communication',
  name: 'communication',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'communicationWorkBench',
      component: () => import(/* webpackChunkName: "communicationWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    // voc
    {
      path: 'myApplication',
      name: 'communicationMyApplication',
      component: RouterView,
      children: [
        {
          path: 'fillInApplication',
          name: 'communicationFillInApplication',
          component: () => import(/* webpackChunkName: "communicationFillInApplication" */ '@/components/applicationForm/applicationInfo.vue')
        },
        {
          path: 'fillInApplication/selectType',
          name: 'communicationSelectType',
          component: () => import(/* webpackChunkName: "communicationSelectType" */ '@/views/communication/VOCApplication/myApplication/selectType/index.vue')
        },
        // {
        //   path: 'fillInApplication/NBselectType',
        //   name: 'NBcommunicationSelectType',
        //   component: () => import(/* webpackChunkName: "communicationSelectType" */ '@/views/communication/NBApplication/NBmyApplication/selectType/index.vue')
        // },
        // {
        //   path: 'fillInApplication/NBcommunicationForm',
        //   name: 'NBcommunicationForm',
        //   component: () => import(/* webpackChunkName: "communicationForm" */ '@/views/communication/NBApplication/NBmyApplication/communicationForm/index.vue')
        // },
        {
          path: 'fillInApplication/communicationForm',
          name: 'communicationForm',
          component: () => import(/* webpackChunkName: "communicationForm" */ '@/views/communication/VOCApplication/myApplication/communicationForm/index.vue')
        },
        {
          path: 'fillInApplication/communicationForm/:id',
          name: 'communicationFormDetail',
          component: () => import(/* webpackChunkName: "communicationFormDetail" */ '@/views/communication/VOCApplication/myApplication/communicationForm/index.vue')
        },
        {
          path: 'applicationDrafts',
          name: 'communicationDrafts',
          component: () => import(/* webpackChunkName: "communicationDrafts" */ '@/views/communication/VOCApplication/applicationDrafts/index.vue')
        },
        {
          path: 'applicationList',
          name: 'communicationList',
          component: () => import(/* webpackChunkName: "communicationList" */ '@/views/communication/VOCApplication/applicationList/index.vue')
        },
        {
          path: 'DraftCertificate',
          name: 'communicationDraftCertificate',
          component: () => import('@/views/communication/VOCApplication/applicationList/DraftCertificate.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'communicationCertificate',
      component: () => import(/* webpackChunkName: "communicationCertificate" */ '@/views/communication/myCertificate/index.vue')
    },
    {
      path: 'myCertificate/:id',
      name: 'communicationCertificateDetail',
      component: () => import(/* webpackChunkName: "communicationCertificateDetail" */ '@/views/communication/myCertificate/detail.vue')
    },
    {
      path: 'myPVOCCertificate',
      name: 'communicationPVOCCertificate',
      component: () => import(/* webpackChunkName: "communicationPVOCCertificate" */ '@/views/communication/PVOCCertificate/index.vue')
    },
    {
      path: 'myPVOCCertificate/:id',
      name: 'communicationPVOCCertificateDetail',
      component: () => import(/* webpackChunkName: "communicationPVOCCertificateDetail" */ '@/views/communication/PVOCCertificate/detail.vue')
    },
    {
      path: 'notice',
      name: 'communicationNotice',
      component: RouterView,
      children: [
        {
          path: 'sampleDelivery',
          name: 'communicationSampleDelivery',
          component: () => import(/* webpackChunkName: "communicationSampleDelivery" */ '@/views/communication/notice/sampleDelivery/index.vue')
        },
        {
          path: 'sampleReceipt',
          name: 'communicationSampleReceipt',
          component: () => import(/* webpackChunkName: "communicationSampleReceipt" */ '@/views/communication/notice/sampleReceipt/index.vue')
        },
        {
          path: 'acceptance',
          name: 'communicationAcceptance',
          component: () => import(/* webpackChunkName: "communicationAcceptance" */ '@/views/communication/notice/acceptance/index.vue')
        },
        {
          path: 'dataReview',
          name: 'communicationDataReview',
          component: () => import(/* webpackChunkName: "communicationDataReview" */ '@/views/communication/notice/dataReview/index.vue')
        }
      ]
    },
    {
      path: 'paymentRecord',
      name: 'communicationPaymentRecord',
      component: RouterView,
      children: [
        {
          path: 'myRemittanceRecord',
          name: 'communicationMyRemittanceRecordy',
          component: () => import(/* webpackChunkName: "communicationMyRemittanceRecordy" */ '@/views/communication/paymentRecord/myRemittanceRecord/index.vue')
        },
        {
          path: 'myRemittanceRecord/add',
          name: 'communicationMyRemittanceRecordyAdd',
          component: () => import(/* webpackChunkName: "communicationMyRemittanceRecordyAdd" */ '@/views/communication/paymentRecord/myRemittanceRecord/setForm.vue')
        },
        {
          path: 'myRemittanceRecord/:id',
          name: 'communicationMyRemittanceRecordyDetail',
          component: () => import(/* webpackChunkName: "communicationMyRemittanceRecordyDetail" */ '@/views/communication/paymentRecord/myRemittanceRecord/details.vue')
        },
        {
          path: 'unpaidRecordForm',
          name: 'communicationUnpaidRecordForm',
          component: () => import(/* webpackChunkName: "communicationUnpaidRecordForm" */ '@/views/communication/paymentRecord/unpaidRecordForm/index.vue')
        },
        {
          path: 'feeNotification',
          name: 'communicationFeeNotification',
          component: () => import(/* webpackChunkName: "communicationFeeNotification" */ '@/views/communication/paymentRecord/feeNotification/index.vue')
        }
      ]
    },
    {
      path: 'PVOCApplication',
      name: 'communicationmyPVOCApplication',
      component: RouterView,
      children: [
        {
          path: 'applicationForm',
          name: 'communicationPVOCApplication',
          component: RouterView,
          children: [
            {
              path: '',
              name: 'communicationPVOCApplication',
              component: () => import(/* webpackChunkName: "communicationPVOCApplication" */ '@/views/customsClearance/applicationForm/myApplicationForm/index.vue')
            },
            {
              path: 'selectType',
              name: 'PVOCSelectType',
              component: () => import(/* webpackChunkName: "PVOCSelectType" */ '@/views/communication/PVOCApplication/applicationForm/selectType/index.vue')
            },
            {
              path: 'communicationForm',
              name: 'communicationPVOCForm',
              component: () => import(/* webpackChunkName: "communicationPVOCForm" */ '@/views/communication/PVOCApplication/applicationForm/PVOCForm/index.vue')
            },
            {
              path: 'communicationForm/:id',
              name: 'communicationPVOCFormDetail',
              component: () => import(/* webpackChunkName: "communicationPVOCFormDetail" */ '@/views/communication/PVOCApplication/applicationForm/PVOCForm/index.vue')
            }
          ]
        },
        {
          path: 'applicationDrafts',
          name: 'communicationPVOCDrafts',
          component: () => import(/* webpackChunkName: "communicationPVOCDrafts" */ '@/views/communication/PVOCApplication/applicationDrafts/index.vue')
        },
        {
          path: 'applicationList',
          name: 'communicationPVOCList',
          component: () => import(/* webpackChunkName: "communicationPVOCList" */ '@/views/communication/PVOCApplication/applicationList/index.vue')
        },
        {
          path: 'DraftCertificate',
          name: 'communicationDraftCertificate',
          component: () => import('@/views/communication/PVOCApplication/applicationList/DraftCertificate.vue')
        }
      ]
    }
  ]
}

export default routes
