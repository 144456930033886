import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import tool from '@/utils/tool'
import Layout from '@/layout/index.vue'
import LayoutNoLeft from '@/layout/index-noleft.vue'
import customsClearance from './modules/customsClearance'
import entrustedInspection from './modules/entrustedInspection'
import communication from './modules/communication'
import internationalProduct from './modules/internationalProduct'
import { useMainStore } from '@/stores/main'
import { ElMessage } from 'element-plus'
import { getPermission, getMeInfo } from '@/api/myProfile'
import { getDictionary } from '@/api/dictionary'
import { getApplicationTypes } from '@/api/customsClearance'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/customsClearance/workBench',
    children: [
      customsClearance,
      entrustedInspection,
      communication,
      internationalProduct
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    path: '/RegisteredChannel',
    name: 'RegisteredChannel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/RegisteredChannel/index.vue')
  },
  {
    path: '/LoginChannel',
    name: 'LoginChannel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginChannel/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../../src/layout/404.vue')
  },
  {
    path: '/policyInfo',
    name: 'policyInfo',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'policyInfo',
        component: () => import(/* webpackChunkName: "policyInfo" */ '../views/policyInfo/index.vue')
      }
    ]
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'myProfileInfo',
        component: () => import(/* webpackChunkName: "myProfileInfo" */ '@/views/myProfile/index.vue')
      }
    ]
  },
  {
    path: '/publicFile',
    name: 'publicFile',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'publicFile',
        component: () => import(/* webpackChunkName: "policyInfo" */ '../views/publicFile/index.vue')
      }
    ]
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'messageList',
        component: () => import(/* webpackChunkName: "messageList" */ '../views/messageList/index.vue')
      }
    ]
  },
  {
    path: '/messageDetails',
    name: 'messageDetails',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'messageDetails',
        component: () => import(/* webpackChunkName: "messageDetails" */ '../views/messageList/messageDetails.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 扁平化菜单
const filterMenuList : any = []
const flattenMenuFun = async (menu: any, bread?: any) => {
  for (let i = 0; i < menu.length; i++) {
    const element = menu[i]
    if (!element.breadcrumbList) {
      element.breadcrumbList = []
    }
    if (bread) {
      element.breadcrumbList.push(...bread)
    }
    element.meta.name = element.name // 用作头部默认选中
    element.meta.reload = true
    // 记录菜单相关业务application_type_id，进行业务列表页排序
    element.meta.application_type_id = element.application_type_id
    element.breadcrumbList.push(element.meta)
    if (element.children) {
      filterMenuList.push(element)
      await flattenMenuFun(element.children, element.breadcrumbList)
    } else {
      filterMenuList.push(element)
    }
  }
  return filterMenuList
}
// 过滤隐藏菜单
const filterHiddenMenu = (data: any) => {
  if (!data) return []
  return data.filter((item: any) => {
    if (item.meta.status === 1) {
      item.children = filterHiddenMenu(item.children)
    }
    return item.meta.status === 1
  })
}
// 当前菜单选中
const onActiveMenu = (to: any) => {
  // 取path最多三级
  const store = useMainStore()
  const path = to.path.indexOf('/') === -1 ? to.path : to.path.split('/').length < 5 ? to.path : `/${to.path.split('/')[1]}/${to.path.split('/')[2]}/${to.path.split('/')[3]}`
  // 当前选中一级菜单active和面包屑
  for (let i = 0; i < store.filterMenu.length; i++) {
    const element: any = store.filterMenu[i]
    if (path === element.path || to.name === element.name) {
      // 业务列表排序
      store.activeMenu = element.breadcrumbList[0].name
      store.businessTypeId = element.breadcrumbList[0].application_type_id
      store.breadcrumb = element.breadcrumbList
      store.activePage = element.breadcrumbList.length > 3 ? element.breadcrumbList[2].name : element.name
      break
    }
  }
}
let isFlag = false
router.beforeEach(async (to, from, next) => {
  // 供应商注册、供应商登录无需处理下面逻辑
  if (to.path === '/RegisteredChannel' || to.path === '/LoginChannel') {
    next()
    return
  }
  // 保存路由参数以防到登录页面丢失
  const queryData = JSON.parse(JSON.stringify(to.query))
  const token = tool.cookie.get('TOKEN_frontend')
  // 路由改变改变菜单默认选中
  const store = useMainStore()
  if (to.path === '/login') {
    tool.cookie.remove('TOKEN_frontend')
    store.menuInfo = []
    store.filterMenu = []
    store.dictionaryList = []
    isFlag = false
    next()
    return
  }
  // todo 如果是第三方登录，route.query判断有auth_code，调接口获取token并缓存，跳转到主页不跳转到登录页面
  // 调接口 await
  // return
  if (token) {
    // 一级菜单
    store.activeMenu = to.path.split('/')[1]
    // 获取菜单信息
    if (!isFlag) {
      isFlag = true
      await getPermission().then(async (res: any) => {
        if (res.code === 200) {
          const menu: any = res.data && res.data.menu ? JSON.parse(JSON.stringify(res.data.menu)) : []
          store.filterMenu = await flattenMenuFun(res.data.menu)
          store.menuInfo = await filterHiddenMenu(menu)
          await onActiveMenu(to)
        } else {
          ElMessage(res.msg)
        }
      })
      // **************************获取字典 start*****************************
      await getDictionary().then((res: any) => {
        if (res.code === 100200) {
          store.dictionaryList = res.result
        } else {
          ElMessage.error(res.msg)
        }
      })
      // **************************获取字典 end*****************************
      // **************************用户信息 start*****************************
      await getMeInfo().then((res: any) => {
        if (res.code === 200) {
          store.USER_INFO = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
      // **************************用户信息 end*****************************
      // **************************获取业务类型 start*****************************
      await getApplicationTypes().then((res: any) => {
        if (res.code === 200) {
          store.applicationTypes = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      await onActiveMenu(to)
    }
    next()
  } else {
    to.path === '/login' ? next() : next({ path: '/login', query: queryData })
  }
})
// 更新包后无法获取到最新的代码，需要特殊处理，重新加载页面
router.afterEach((to, from, failure) => {
  if (failure) {
    location.reload()
  }
})
// 更新包后路由访问错误，重新加载页面,加载最新的chunk资源
router.onError(() => {
  location.reload()
  // const pattern = /Loading chunk/g
  // const isChunkLoadFailed = error.message.match(pattern)
  // if (isChunkLoadFailed) {
  //   console.log('isChunkLoadFailed')
  //   location.reload()
  // }
})

export default router
